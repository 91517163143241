import "./style.css";

import * as THREE from "three";
//  import * as dat from "lil-gui";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import firefliesVertexShader from "./shaders/fireflies/vertex.glsl";
import firefliesFragmentShader from "./shaders/fireflies/fragment.glsl";
import smokeVertexShader from "./shaders/smoke/vertex.glsl";
import smokeFragmentShader from "./shaders/smoke/fragment.glsl";

// import Stats from "stats-js/src/Stats.js";
import { MarchingCubes } from "three/examples/jsm/objects/MarchingCubes.js";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js";
import  {SimplexNoise} from "three/examples/jsm/math/SimplexNoise.js";
import gsap from "gsap";
import * as CANNON from "cannon-es";
// import { SSREffect } from "screen-space-reflections"
// import * as POSTPROCESSING from "postprocessing"



/**
 * Mouse
 */
const mouse = new THREE.Vector2();



document.body.addEventListener( 'mousemove', onDocumentMouseMove.bind(this), false );
document.body.addEventListener( 'touchstart', onDocumentTouchStart.bind(this), false );
document.body.addEventListener( 'touchmove', onDocumentTouchMove.bind(this), false );

function onDocumentMouseMove( event ) {
  mouse.x = (event.clientX / sizes.width) * 2 - 1;
    mouse.y = -(event.clientY / sizes.height) * 2 + 1;
}
function onDocumentTouchStart( event ) {
  if ( event.touches.length === 1 ) {
    mouse.x = (event.clientX / sizes.width) * 2 - 1;
    mouse.y = -(event.clientY / sizes.height) * 2 + 1;
  }
}
function onDocumentTouchMove( event ) {
  if ( event.touches.length === 1 ) {
    mouse.x = (event.clientX / sizes.width) * 2 - 1;
    mouse.y = -(event.clientY / sizes.height) * 2 + 1;
  }
}

//FOG

class Fog {
  constructor() {
    this.uniforms = {
      time: {
        type: 'f',
        value: 0
      },
      tex: {
        type: 't',
        value: null
      }
    };
    this.num =3;
    this.obj = null;
  }
  createObj(tex) {
    // Define Geometries
    const geometry = new THREE.InstancedBufferGeometry();
    const baseGeometry = new THREE.PlaneBufferGeometry(4, 4, 1, 1);


    // Copy attributes of the base Geometry to the instancing Geometry
    geometry.setAttribute('position', baseGeometry.attributes.position);
    geometry.setAttribute('normal', baseGeometry.attributes.normal);
    geometry.setAttribute('uv', baseGeometry.attributes.uv);
    geometry.setIndex(baseGeometry.index);

// Define attributes of the instancing geometry
const instancePositions = new THREE.InstancedBufferAttribute(new Float32Array(this.num * 3), 3,  false);

const delays = new THREE.InstancedBufferAttribute(new Float32Array(this.num), 1, false);
const rotates = new THREE.InstancedBufferAttribute(new Float32Array(this.num), 1, false);



const mpos=[]

 mpos[0]=[-2,0.25,0 ]
  mpos[1]=[0,0.5,0.5]
  mpos[2]=[2,0,0.25]
 
for ( var i = 0, ul = this.num; i < ul; i++ ) {
 
  instancePositions.setXYZ(i, mpos[i][0],mpos[i][1],mpos[i][2] );


  delays.setXYZ(i, Math.random());
  rotates.setXYZ(i,  Math.random() * 2 + 1);

}
geometry.setAttribute('instancePosition', instancePositions);




geometry.setAttribute('delay', delays);
geometry.setAttribute('rotate', rotates);

// Define Material
const material = new THREE.RawShaderMaterial({
  uniforms: this.uniforms,
  vertexShader: smokeVertexShader,
  fragmentShader: smokeFragmentShader,
 transparent: true,
  depthWrite: false,
blending: THREE.AdditiveBlending,
});
this.uniforms.tex.value = tex;

// Create Object3D
const tmp1 =  new THREE.Mesh(geometry, material);
scene.add(tmp1);
tmp1.position.x=0
tmp1.position.y=1
tmp1.position.z=-4
tmp1.rotation.x=0
tmp1.rotation.y=0
tmp1.rotation.z=0



const tmp2 =  new THREE.Mesh(geometry, material);
scene.add(tmp2);
tmp2.position.x= 2.8318
tmp2.position.y=1
tmp2.position.z=-0.8556
tmp2.rotation.x=0
tmp2.rotation.y=-1.593
tmp2.rotation.z=0



const tmp3 =  new THREE.Mesh(geometry, material);
scene.add(tmp3);
tmp3.position.x= -1.2
tmp3.position.y=2.4
tmp3.position.z=1.7
tmp3.rotation.x=2.8
tmp3.rotation.y=-12
tmp3.rotation.z=0.25



// const tmp4 =  new THREE.Mesh(geometry, material);
// scene.add(tmp4);
// tmp4.position.x= -1.2
// tmp4.position.y=2.4
// tmp4.position.z=1.7
// tmp4.rotation.x=2.8
// tmp4.rotation.y=-12
// tmp4.rotation.z=0.25
// tmp4.scale.set(2,2,2)

//   gui.add( tmp4.position, 'x', -15, 15).step(0.0001).name('px').onChange(regenerateGeometry)
//   gui.add( tmp4.position, 'y', -15, 15).step(0.0001).name('py').onChange(regenerateGeometry)
//   gui.add( tmp4.position, 'z', -15, 15).step(0.0001).name('pz').onChange(regenerateGeometry)
//   gui.add( tmp4.rotation, 'x', -15, 15).step(0.0001).name('rx').onChange(regenerateGeometry)
//   gui.add( tmp4.rotation, 'y', -15, 15).step(0.0001).name('ry').onChange(regenerateGeometry)
//   gui.add( tmp4.rotation, 'z', -15, 15).step(0.0001).name('rz').onChange(regenerateGeometry)

//  function regenerateGeometry(e) {


//  console.log( tmp4.position.x,tmp4.position.y,tmp4.position.z )
//  console.log( tmp4.rotation.x,tmp4.rotation.y ,tmp4.rotation.z  )
 
//  }

}
render(time) {
this.uniforms.time.value += time;
}
}

const fog = new Fog();



//FIGURE
const noise = new SimplexNoise();

const baseForce = 5;
const off = 0.05;

class Wind {
  constructor(figure) {
    const { count } = figure.geometry.attributes.position;
    this.figure = figure;

    this.force = baseForce / count;

    this.clock = new THREE.Clock();
    this.direction = new THREE.Vector3(0.5, 0, -0.7);
    this.flowfield = new Array(count);

    this.update();

    this.bindEvents();
  }

  bindEvents() {
   window.addEventListener("mousemove", this.onMouseMove.bind(this));
  }

  onMouseMove({ clientX: x, clientY: y }) {

    const { innerWidth: W, innerHeight: H } = window;

    gsap.to(this.direction, {
      duration: 0.8,
      x: x / W - 0.5,
      y: -(y / H) + 0.5
    });
  }

  update() {
    const time = this.clock.getElapsedTime();

    const { position } = this.figure.geometry.attributes;
    const size = this.figure.geometry.parameters.widthSegments;

    for (let i = 0; i < position.count; i++) {
      const col = i % (size + 1);
      const row = Math.floor(i / (size + 1));

      const force =
        (noise.noise3d(row * off, col * off, time) * 0.5 + 0.5) * this.force;

      this.flowfield[i] = this.direction.clone().multiplyScalar(force);
    }
  }
}







const size = 12;
const mass = 1;


class Figure {
  constructor(roty,px,py,pz) {


    this.roty=roty
    this.px=px
    this.py=py
    this.pz=pz


    this.scene = scene;
    this.world = world;

    this.loader = new THREE.TextureLoader();



    this.sizes = new THREE.Vector2(0, 0);

    this.bufferV = new THREE.Vector3();
    this.bufferV2 = new CANNON.Vec3();

    this.getSizes();

    this.createMesh();
    this.createStitches();
  }

  getSizes() {

    this.sizes.set(1, 1);
 
  }

  createMesh() {
    
    this.geometry = new THREE.PlaneGeometry(1.5, 4, size, size); 
    const { position } = this.geometry.attributes;

    for (let i = 0; i < position.count; i++) {
      const pos = new THREE.Vector3(
        position.getX(i) +this.px,
        position.getY(i) +this.py ,
        position.getZ(i)+this.pz
      );
      const axis = new THREE.Vector3( 0, 1, 0 );
      pos.applyAxisAngle(axis,this.roty)
     position.setXYZ(i, pos.x, pos.y, pos.z );
    }

    this.mesh = new THREE.Mesh(this.geometry, bandieraMaterial);


    this.scene.add(this.mesh);
  }

  createStitches() {
    const particleShape = new CANNON.Particle();
    const { position } = this.geometry.attributes;
    const { x: width, y: height } = this.sizes;

    this.stitches = [];

    for (let i = 0; i < position.count; i++) {
      const row = Math.floor(i / (size + 1));

      const pos = new CANNON.Vec3(
        position.getX(i) * width,
        position.getY(i) * height,
        position.getZ(i)
      );

      const stitch = new CANNON.Body({
        mass: row === 0 ? 0 : mass / position.count,
        linearDamping: 0.8,
        position: pos,
        shape: particleShape
      });

      this.stitches.push(stitch);
      this.world.addBody(stitch);
    }

    for (let i = 0; i < position.count; i++) {
      const col = i % (size + 1);
      const row = Math.floor(i / (size + 1));

      if (col < size) this.connect(i, i + 1);
      if (row < size) this.connect(i, i + size + 1);
    }
  }

  connect(i, j) {
    const c = new CANNON.DistanceConstraint(this.stitches[i], this.stitches[j]);

    this.world.addConstraint(c);
  }

  applyWind(wind) {
    const { position } = this.geometry.attributes;

    for (let i = 0; i < position.count; i++) {
      const stitch = this.stitches[i];

      const windNoise = wind.flowfield[i];
      const tempPosPhysic = this.bufferV2.set(
        windNoise.x,
        windNoise.y,
        windNoise.z
      );

      stitch.applyForce(tempPosPhysic, CANNON.Vec3.ZERO);
    }
  }

  update() {
    const { position } = this.geometry.attributes;
    const { x: width, y: height } = this.sizes;

    for (let i = 0; i < position.count; i++) {
      const p = this.bufferV.copy(this.stitches[i].position);

      position.setXYZ(i, p.x / width, p.y / height, p.z);
    }

    position.needsUpdate = true;
  }
}


/**
 * Base
 */
// Debug

 //const gui = new dat.GUI();
//  const debugObject = {};

// Canvas
const canvas = document.querySelector("canvas.webgl");
const loading = document.querySelector("div.loading");
const after = document.querySelector("div.loading__after");
// Scene
const scene = new THREE.Scene();

//sscene.fog = new THREE.Fog(0xffffff, 9, 20);

scene.fog = new THREE.FogExp2(0xa7c655, 0.03);

// LIGHTS
const pointLight1 = new THREE.PointLight(0xe8ffa0, 17, 40);

pointLight1.position.set(-4, 4.5, -0.3);

scene.add(pointLight1);

// const pointLight2 = new THREE.PointLight(0xff9ee5, 10, 40);

// pointLight2.position.set(1.2, 1.2, -3.3);

// scene.add(pointLight2);

const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
scene.add(ambientLight);

/**
 * Loaders
 */

//MANAGER
const ctot = 7;
let cnumb = 0;
function cLoad() {
  cnumb = cnumb + 1;
  //console.log(cnumb);
  const percent=cnumb/ctot
  //console.log(percent);

  gsap.to(after, {scaleX: percent, duration: 1.5});


  if (cnumb == ctot) {
    //console.log("ALL LOADED");

  
    loading.style.display = "none";

    // controls.object.position.x = -0.17722946347765178;

    // controls.object.position.y = -0.5710970346941027;

    // controls.object.position.z = 1.9085171977844069;

    gsap.to(camera.position, {
      duration: 2,
      x: -0.17722946347765178,
      y: -0.5710970346941027,
      z: 1.9085171977844069,
      onUpdate: function () {
        controls.update();
      },
      onComplete: function () {
        controls.enabled = true;
      },
    });
  }
}
const manager = new THREE.LoadingManager();
// manager.onStart = function (url, itemsLoaded, itemsTotal) {
//   //console.log(
//     "Started loading file: " +
//       url +
//       ".\nLoaded " +
//       itemsLoaded +
//       " of " +
//       itemsTotal +
//       " files."
//   );
// };

manager.onLoad = function () {
  //console.log("load");
  cLoad();
};

// manager.onProgress = function (url, itemsLoaded, itemsTotal) {
//   console.log(
//     "Loading file: " +
//       url +
//       ".\nLoaded " +
//       itemsLoaded +
//       " of " +
//       itemsTotal +
//       " files."
//   );
// };

manager.onError = function (url) {
  console.log("There was an error loading " + url);
};

// Draco loader
const dracoLoader = new DRACOLoader(manager);
dracoLoader.setDecoderPath("assets/3d/draco/");

// GLTF loader
const gltfLoader = new GLTFLoader(manager);
gltfLoader.setDRACOLoader(dracoLoader);

// /**
//  * Materials
//  */
const bandieraMaterial = new THREE.MeshPhongMaterial({});

bandieraMaterial.side=THREE.DoubleSide
// bandieraMaterial.forceSinglePass = 1;
bandieraMaterial.transparent = 1;
bandieraMaterial.depthTest = 0;
//  bandieraMaterial.depthWrite = 1;
const backgroundMaterial = new THREE.MeshBasicMaterial({  });

const bakedMaterial = new THREE.MeshBasicMaterial({});
bakedMaterial.transparent = 1;
//bakedMaterial.side=THREE.DoubleSide
bakedMaterial.depthTest = 0;

const groundMaterial = new THREE.MeshBasicMaterial({});

const whiteMaterial = new THREE.MeshPhongMaterial({
  color: 0xddc0ad,
  shininess:1
  // roughness: 0.5,
  // metalness : 0.5
  //color: 0xca1d1d,
});
//whiteMaterial.color.convertSRGBToLinear();
// const whiteImageMaterial = new THREE.MeshBasicMaterial({});

const glassMaterial = new THREE.MeshPhysicalMaterial({
  color: 0xddfcfd,

  metalness: 0,
  roughness: 0.01,
  transmission: 0.94,
  ior: 1,
  reflectivity: 0.1,
  thickness: 4,

  clearcoat: 0.8,
  clearcoatRoughness: 1,
  normalScale: new THREE.Vector2(0.3),

  clearcoatNormalScale: new THREE.Vector2(0.3),
});

//glassMaterial.color.convertSRGBToLinear();
// glassMaterial.transparent = 1;
//  glassMaterial.depthTest = 0;
/**
 * Model
 */

//BLOB
const blobMaterial = new THREE.MeshPhysicalMaterial({
  color: 0xffffff,

  metalness: 0,
  roughness: 0.01,
  transmission: 0.99,
  ior: 1,
  reflectivity: 0.1,
  thickness: 1,

  clearcoat: 0.8,
  clearcoatRoughness: 1,
  normalScale: new THREE.Vector2(0.15),

  clearcoatNormalScale: new THREE.Vector2(0.5)
});

//blobMaterial.color.convertSRGBToLinear();

const blobController = {
  resolution: 42,
  speed: 0.5,
  numBlobs: 6,
  isolation: 800,
};
let blobtime = 0;

// MARCHING CUBES
const blob = new MarchingCubes(
  blobController.resolution,
  blobMaterial,
  false,
  false,
  10000
);
blob.position.set(0, 5, -2);
blob.scale.set(-2, -2, -2);
blob.enableUvs = false;
blob.enableColors = false;
scene.add(blob);
//console.log(blob)
blob.renderOrder=1000
// this controls content of marching cubes voxel field
function updateCubes(object, blobtime, numblobs) {
  object.reset();
  // fill the field with some metaballs
  const subtract = 12;
  const strength = 1.2 / ((Math.sqrt(numblobs) - 1) / 4 + 1);
  for (let i = 0; i < numblobs; i++) {
    const ballx =
      Math.sin(i + 1.26 * blobtime * (1.03 + 0.5 * Math.cos(0.21 * i))) * 0.27 +
      0.5;
    const bally =
      Math.abs(Math.cos(i + 1.12 * blobtime * Math.cos(1.22 + 0.1424 * i))) *
      0.77; // dip into the floor
    const ballz =
      Math.cos(i + 1.32 * blobtime * 0.1 * Math.sin(0.92 + 0.53 * i)) * 0.27 +
      0.5;
    object.addBall(ballx, bally, ballz, strength, subtract);
  }
}

//CLOUD

// var cloudParticles = [];
// var cloudGeo = new THREE.PlaneBufferGeometry(5, 5);
// var cloudMaterial = new THREE.MeshLambertMaterial({
//   transparent: 1,
// });
// cloudMaterial.depthTest = 0;
// for (let p = 0; p < 4; p++) {
//   let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
//   cloud.position.set(
//     Math.random() * 2 + 3,
//     Math.random() * 1 + 3.5,
//     Math.random() * 2 + 1
//   );
//   cloud.rotation.x = 1.16;
//   cloud.rotation.y = -0.12;
//   cloud.rotation.z = Math.random() * 2 * Math.PI;
//   cloud.material.opacity = 1;
//   cloud.renderOrder = 2;
//   cloudParticles.push(cloud);
//   scene.add(cloud);
// }


/**
 * Fireflies
 */
// Geometry
const firefliesGeometry = new THREE.BufferGeometry();
const firefliesCount = 15;
const positionArray = new Float32Array(firefliesCount * 3);
const scaleArray = new Float32Array(firefliesCount);

for (let i = 0; i < firefliesCount; i++) {
  positionArray[i * 3 + 0] = 0 + (Math.random() - 0.5) * 1.5;
  positionArray[i * 3 + 1] = -0.5 + Math.random() * 0.5;
  positionArray[i * 3 + 2] = -3 + (Math.random() - 0.5) * 1.5;

  scaleArray[i] = Math.random();
}

firefliesGeometry.setAttribute(
  "position",
  new THREE.BufferAttribute(positionArray, 3)
);
firefliesGeometry.setAttribute(
  "aScale",
  new THREE.BufferAttribute(scaleArray, 1)
);

// Material
const firefliesMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
    uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
    uSize: { value: 200 },
  },
  precision: "lowp",
  vertexShader: firefliesVertexShader,
  fragmentShader: firefliesFragmentShader,
  transparent: 1,

  blending: THREE.AdditiveBlending,
  depthWrite: false,
});

// gui.add(firefliesMaterial.uniforms.uSize, 'value').min(0).max(500).step(1).name('firefliesSize')

// Points
const fireflies = new THREE.Points(firefliesGeometry, firefliesMaterial);
fireflies.renderOrder = 5;
scene.add(fireflies);
var ground = [];
var background = [];
var baked = [];
var glass = [];
var whiteImage = [];
var white = [];



gltfLoader.load("assets/3d/s1.glb", (gltf) => {
  scene.add(gltf.scene);
  // console.log(gltf.scene)

  for (let i = 0; i < 1; i++) {
    var nb = ("0" + (i + 1)).slice(-2);
    ground["ground_" + nb] = gltf.scene.children.find(
      (child) => child.name === "ground_" + nb
    );
    ground["ground_" + nb].material = groundMaterial;
    ground["ground_" + nb].renderOrder = 1;
  }

  for (let i = 0; i < 4; i++) {
    var nb = ("0" + (i + 1)).slice(-2);
    background["background_" + nb] = gltf.scene.children.find(
      (child) => child.name === "background_" + nb
    );
    background["background_" + nb].material = backgroundMaterial;
    background["background_" + nb].renderOrder = 1;
  }

  for (let i = 0; i < 6; i++) {
    var nb = ("0" + (i + 1)).slice(-2);
    baked["baked_" + nb] = gltf.scene.children.find(
      (child) => child.name === "baked_" + nb
    );
    baked["baked_" + nb].material = bakedMaterial;

    baked["baked_" + nb].renderOrder = 4;
  }

  for (let i = 0; i < 2; i++) {
    var nb = ("0" + (i + 1)).slice(-2);
    glass["glass_" + nb] = gltf.scene.children.find(
      (child) => child.name === "glass_" + nb
    );
    glass["glass_" + nb].material = glassMaterial;
    glass["glass_" + nb].renderOrder = 5;
  }

  for (let i = 0; i < 1; i++) {
    var nb = ("0" + (i + 1)).slice(-2);
    whiteImage["whiteImage_" + nb] = gltf.scene.children.find(
      (child) => child.name === "whiteImage_" + nb
    );
    whiteImage["whiteImage_" + nb].material = groundMaterial;
    whiteImage["whiteImage_" + nb].renderOrder = 4;
  }

  for (let i = 0; i < 11; i++) {
    var nb = ("0" + (i + 1)).slice(-2);
    white["white_" + nb] = gltf.scene.children.find(
      (child) => child.name === "white_" + nb
    );
    white["white_" + nb].material = whiteMaterial;
    white["white_" + nb].renderOrder = 5;
  }










  //ORDER
  baked["baked_06"].renderOrder = 2;
  baked["baked_01"].renderOrder = 3;
  baked["baked_02"].renderOrder = 3;
  background["background_02"].renderOrder = 3;


  baked["baked_05"].renderOrder = 100;



});

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;

  if (camera.aspect < 0.75) {
    camera.fov = 55;

   scene.position.y = -0.2;
  } else if (camera.aspect > 2.5) {
    camera.fov = 35;

    scene.position.y = 0;
  } else {
    camera.fov = 45;
    scene.position.y = 0;
  }

  // Update fireflies
  firefliesMaterial.uniforms.uPixelRatio.value = Math.min(
    window.devicePixelRatio,
    2

  );


  

  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera

const camera = new THREE.PerspectiveCamera(
  45,
  sizes.width / sizes.height,
  0.7,
  20
);

camera.position.x = 1.4014514019798465;
camera.position.y = 1.6038131947772607;
camera.position.z = 3.262333775673199;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);

controls.enableZoom = true;
controls.enableDamping = true;
controls.enablePan = false;

controls.minDistance = 0.7;
controls.maxDistance = 2.2;

controls.enabled = false;
//OrbitControls.minZoom and OrbitControls.maxZoom

// controls.addEventListener("change", (event) => {
//   // console.log(controls.object.position);
//   //   //     // console.log(profile.rotation.z)
//   //   //    console.log(camera.position)
//   //   // // console.log(   controls.getAzimuthalAngle ());
// });

/**
 * Renderer
 */
const pixel = Math.min(window.devicePixelRatio, 2);
let alias = false;
if (pixel == 1) {
  alias = true;
}
// console.log("alias", alias, pixel);
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,

  powerPreference: "high-performance",
  antialias: true

});

renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.setClearColor(0x131810, 1);


renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding;
//   THREE.NoToneMapping
// THREE.LinearToneMapping
// THREE.ReinhardToneMapping
// THREE.CineonToneMapping
// THREE.ACESFilmicToneMapping
// THREE.CustomToneMapping
//   renderer.toneMapping = THREE.ReinhardToneMapping

//  renderer.toneMappingExposure = 1;
// renderer.shadowMap.enabled = true
// renderer.shadowMap.type = THREE.PCFSoftShadowMap

// THREE.ColorManagement.enabled = true;



//POSTPROCESSING
// const options = {
// 	intensity: 0,
// 	exponent: 1,
// 	distance: 10,
// 	fade: 0,
// 	roughnessFade: 1,
// 	thickness: 10,
// 	ior: 1.45,
// 	maxRoughness: 1,
// 	maxDepthDifference: 10,
// 	blend: 0.9,
// 	correction: 1,
// 	correctionRadius: 1,
// 	blur: 0.5,
// 	blurKernel: 1,
// 	blurSharpness: 10,
// 	jitter: 0,
// 	jitterRoughness: 0,
// 	steps: 20,
// 	refineSteps: 5,
// 	missedRays: true,
// 	useNormalMap: true,
// 	useRoughnessMap: true,
// 	resolutionScale: 1,
// 	velocityResolutionScale: 1
// }
// const composer = new POSTPROCESSING.EffectComposer(renderer)

// const ssrEffect = new SSREffect(scene, camera, options)

// const ssrPass = new POSTPROCESSING.EffectPass(camera, ssrEffect)

// composer.addPass(ssrPass)



/**
 * BASIS
 */

const ktx2Loader = new KTX2Loader(manager);
ktx2Loader.setTranscoderPath("assets/3d/basis/");
ktx2Loader.detectSupport(renderer);
const prefix = "ktx2";
//  const ktx2Loader = new THREE.TextureLoader(manager);
//  const prefix = "png";


ktx2Loader.load(
  "assets/3d/bandiera." + prefix,
  function (texture) {
    //texture.flipY = false;
  
    texture.encoding = THREE.sRGBEncoding;
    bandieraMaterial.map = texture;
      bandieraMaterial.needsUpdate = true;
    // console.log("bandiera-loaded");
    cLoad();
  },
  function () {
    //console.log("onProgress-bandiera");
  },
  function (e) {
    //console.log("error-bandiera");
    console.error(e);
  }
);



ktx2Loader.load(
  "assets/3d/baked-de." + prefix,
  function (texture) {
    texture.flipY = false;
    texture.encoding = THREE.sRGBEncoding;

    bakedMaterial.map = texture;
    bakedMaterial.needsUpdate = true;
    //console.log("backed-loaded");
    cLoad();
  },
  function () {
    //console.log("onProgress-backed");
  },
  function (e) {
    //console.log("error-backed");
    console.error(e);
  }
);

ktx2Loader.load(
  "assets/3d/background-de." + prefix,
  function (texture) {
    texture.flipY = false;
    texture.encoding = THREE.sRGBEncoding;
    // scene.environment = texture;
    backgroundMaterial.map = texture;
    backgroundMaterial.needsUpdate = true;

  
    //console.log("background-loaded");
    cLoad();
  },
  function () {
    //console.log("onProgress-bakgroeund");
  },
  function (e) {
    //console.log("error-bakgroeund");
    console.error(e);
  }
);
ktx2Loader.load(
  "assets/3d/ground-de." + prefix,
  function (texture) {
    texture.flipY = false;
    texture.encoding = THREE.sRGBEncoding;

    groundMaterial.map = texture;
    groundMaterial.needsUpdate = true;
    //console.log("ground-loaded");
    cLoad();
  },
  function () {
    //console.log("onProgress-ground");
  },
  function (e) {
    //console.log("error-ground");
    console.error(e);
  }
);

ktx2Loader.load(
  "assets/3d/normal." + prefix,
  function (texture) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(20, 20);

    glassMaterial.normalMap = texture;
    glassMaterial.clearcoatNormalMap = texture;

    glassMaterial.needsUpdate = true;


  
    blobMaterial.normalMap = texture;
    blobMaterial.clearcoatNormalMap = texture;

    blobMaterial.needsUpdate = true;

    //console.log("normal-loaded");
    cLoad();
  },
  function () {
    //console.log("onProgress-normal");
  },
  function (e) {
    //console.log("error-normal");
    console.error(e);
  }
);


ktx2Loader.load("assets/3d/smoke."+prefix, function (texture) {
  texture.encoding = THREE.sRGBEncoding;

  // cloudMaterial.map = texture;
  // cloudMaterial.needsUpdate = true;


  fog.createObj(  texture);



  //console.log("smoke-loaded")
  cLoad()
}, function () {

	//console.log( 'onProgress-smoke' );

}, function ( e ) {
	//console.log( 'error-smoke' );
	console.error( e );

});





//  const stats = new Stats();
//  stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
//  document.body.appendChild(stats.dom);

// debugObject.createSphere = () => {
//   createSphere(0.04, {
//     x: 0 + Math.random() * 1,
//     y: 2 + Math.random() * 1,
//     z: -1.5 + Math.random() * 1,
//   });
// };

// gui.add(debugObject, "createSphere");

// Reset
// debugObject.reset = () => {
//   for (const object of objectsToUpdate) {
//     // Remove body

//     world.removeBody(object.body);

//     // Remove mesh
//     scene.remove(object.mesh);
//   }

//   objectsToUpdate.splice(0, objectsToUpdate.length);
// };
// gui.add(debugObject, "reset");

/**
 * Physics
 */
const world = new CANNON.World();
world.broadphase = new CANNON.SAPBroadphase(world);
world.allowSleep = true;
world.gravity.set(0, -9.82, 0);

world.solver.iterations = 10;

// Default material
const defaultMaterial = new CANNON.Material("default");
const defaultContactMaterial = new CANNON.ContactMaterial(
  defaultMaterial,
  defaultMaterial,
  {
    friction: 0.1,
    restitution: 0.7,
  }
);
world.defaultContactMaterial = defaultContactMaterial;

// Floor
const floorShape = new CANNON.Plane();
const floorBody = new CANNON.Body();
floorBody.position.y = -0.922;
floorBody.mass = 0;
floorBody.addShape(floorShape);
floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(-1, 0, 0), Math.PI * 0.5);
world.addBody(floorBody);

/**
 * Utils
 */
const objectsToUpdate = [];

// Create sphere
const sphereGeometry = new THREE.SphereGeometry(1, 20, 20);
// const sphereMaterial = new THREE.MeshStandardMaterial({
//   color: 0x000000,
//    metalness: 1,
// roughness: 0.4,
// });




const lavaMaterial = new THREE.MeshStandardMaterial({color: '#F00', roughness: 0.2, metalness: 0.3})
const createSphere = (radius, position) => {
  // Three.js mesh
  const mesh = new THREE.Mesh(sphereGeometry, lavaMaterial );
 
  mesh.scale.set(radius, radius, radius);
  mesh.position.copy(position);
  scene.add(mesh);
  mesh.renderOrder = 1000;
  // Cannon.js body
  const shape = new CANNON.Sphere(radius);

  const body = new CANNON.Body({
    mass: 3,
    position: new CANNON.Vec3(0, 3, 0),
    shape: shape,
    material: defaultMaterial,
  });
  body.position.copy(position);

  world.addBody(body);

  // Save in objects
  objectsToUpdate.push({ mesh, body });
};

/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster();
let currentIntersect = null;

let ball = false;

function random(mn, mx) {
  return Math.random() * (mx - mn) + mn;
}
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}




// window.addEventListener(
//   "touchend",
//   (event) => {
//     mouse.x = (event.clientX / sizes.width) * 2 - 1;
//     mouse.y = -(event.clientY / sizes.height) * 2 + 1;
//   },
//   true
// );



  /**
 * FIGURE
 */


   const figure= new Figure(0, 0,6,0);




//   let figure = new Figure( 0.5251, 0.6194, 6.2, 4.4);
//   figure.mesh.renderOrder = 1000;
//  let guiControls = new function() {
 
 
 
//    this.roty=0.5251
//    this.px= 0.6194
//    this.py= 6.2
//      this.pz=4.6
  
   
//  }
 
 
//  gui.add(guiControls, 'px', -15, 15).step(0.0001).name('px').onChange(regenerateGeometry)
//  gui.add(guiControls, 'py', -15, 15).step(0.0001).name('py').onChange(regenerateGeometry)
//  gui.add(guiControls, 'pz', -15, 15).step(0.0001).name('pz').onChange(regenerateGeometry)
//  gui.add(guiControls, 'roty', -2, 2).step(0.0001).name('roty').onChange(regenerateGeometry)
 

//  function regenerateGeometry() {
//    scene.remove( figure.mesh );
//    figure = new Figure(guiControls.roty, guiControls.px, guiControls.py, guiControls.pz);
//    figure.mesh.renderOrder = 1000;
//  console.log( "figure = new Figure("+guiControls.roty+", "+guiControls.px+", "+guiControls.py+", "+guiControls.pz+");")
 
 
 
//  }

 const wind = new Wind(figure.mesh);
 
 figure.mesh.renderOrder = 4;
 figure.mesh.rotation.x=0
 figure.mesh.rotation.y=-4.5
 figure.mesh.rotation.z=-0.1181
 figure.mesh.position.x=3.4
 figure.mesh.position.y=-1.8618
 figure.mesh.position.z=2.0943


//  gui.add( figure.mesh.rotation, 'x', -15, 15).step(0.0001).name('x')
//  gui.add( figure.mesh.rotation, 'y', -15, 15).step(0.0001).name('y')
//  gui.add( figure.mesh.rotation, 'z', -15, 15).step(0.0001).name('z')

//  gui.add( figure.mesh.position, 'x', -15, 15).step(0.0001).name('x')
//  gui.add( figure.mesh.position, 'y', -15, 15).step(0.0001).name('y')
//  gui.add( figure.mesh.position, 'z', -15, 15).step(0.0001).name('z')

/**
 * Animate
 */
const clock = new THREE.Clock();
let oldElapsedTime = 0;
const tick = () => {
//  stats.begin();
  const elapsedTime = clock.getElapsedTime();
  const delta = elapsedTime - oldElapsedTime;
  oldElapsedTime = elapsedTime;

  // Update physics
  world.step(1 / 60, delta, 3);


//UPDATE FOG
 fog.render(delta);



  //UPDATE WIND
  if ( wind) {
  wind.update();
  figure.update();
  figure.applyWind(wind);
  


 }
  for (const object of objectsToUpdate) {
    object.mesh.position.copy(object.body.position);
    object.mesh.quaternion.copy(object.body.quaternion);
  }

  // Cast a ray
  raycaster.setFromCamera(mouse, camera);

  if (white["white_02"]) {
    const objectsToTest = [
      background["background_04"],
      baked["baked_04"],
      white["white_02"],
      white["white_03"],
      white["white_04"],
      white["white_05"],
      white["white_06"],
      white["white_07"],
      white["white_08"],
      white["white_09"],
      white["white_10"],
      white["white_11"]
    ];
    const intersects = raycaster.intersectObjects(objectsToTest);

    if (intersects.length) {
      //console.log(intersects[0].object.name);

      if ( 
        !currentIntersect &&
        intersects[0].object.name == "baked_04" &&
        ball === false
      ) {

        createSphere(0.05, {
          x: 0 + Math.random() * 1,
          y: 2 + Math.random() * 1,
          z: -1.5 + Math.random() * 1,
        });

        ball = true;

        currentIntersect = intersects[0];
      } else if (!currentIntersect && intersects[0].object.name == "background_04" && intersects[0].object.name != "baked_04" && !gsap.getTweensOf([intersects[0].object.rotation]).length) {
    
      intersects[0].object.rotation.y=0
     gsap.to(intersects[0].object.rotation, {
         duration: 5,
  
           y: +Math.PI*2,
      
           ease: "Elastic.easeOut",
        });
     
    }else if (
        !currentIntersect &&
        intersects[0].object.name != "baked_04" &&
        intersects[0].object.name != "background_04" &&
        !gsap.getTweensOf([intersects[0].object.rotation]).length
      ) {
  

        const crot = [random(-Math.PI / 2, Math.PI / 2), 0, 0];

        shuffle(crot);

        const cscale = [random(1, 2), random(0.6, 1), 1];

        shuffle(cscale);

       // console.log("mouse enter");
        gsap.to(intersects[0].object.rotation, {
          duration: 0.7,
          x: crot[0],
          y: crot[1],
          z: crot[2],

          ease: "Elastic.easeOut",
        });
        gsap.to(intersects[0].object.scale, {
          duration: 0.7,
          x: cscale[0],
          y: cscale[1],
          z: cscale[2],
          ease: "Elastic.easeOut",
        });

        gsap.to(intersects[0].object.rotation, {
          duration: 0.7,
          delay: 0.7,
          x: 0,
          y: 0,
          z: 0,
          ease: "Elastic.easeOut",
        });

        gsap.to(intersects[0].object.scale, {
          duration: 0.7,
          delay: 0.7,
          x: 1,
          y: 1,
          z: 1,
          ease: "Elastic.easeOut",
        });
      }

      currentIntersect = intersects[0];
    } else {
      currentIntersect = null;
    }
  }

  blobtime += delta * blobController.speed * 0.5;

  // marching cubes
  updateCubes(blob, blobtime, blobController.numBlobs);

  // Update materials

  firefliesMaterial.uniforms.uTime.value = elapsedTime;





  // Update controls
  controls.update();


//CLOUD
  // cloudParticles.forEach((p) => {
  //   p.rotation.z -= 0.001;
  // });




  if (baked["baked_02"]) {
    baked["baked_02"].position.y = 2 + Math.sin(elapsedTime * 1) * 0.5;
  }

  if (white["white_01"]) {
    white["white_01"].rotation.x -= 0.007;
    white["white_01"].rotation.y -= 0.007;
  }




  if (background["background_03"]) {

    background["background_03"].rotation.y -= 0.001;
  }


  // Render

renderer.render(scene, camera);
   // composer.render()
  // Call tick again on the next frame
  window.requestAnimationFrame(tick);

//  stats.end();
};
window.dispatchEvent(new Event("resize"));
tick();
